.qr-reader {
    width: 600px;
    height: 600px;
    margin: 0 auto;
    position: relative;
    padding:50px;
  }
  
  .qr-reader video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .qr-reader .qr-box {
    width: 100% !important;
    left: 0 !important;
  }
  
  .qr-reader .qr-frame {
    position: absolute;
    fill: none;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  .qr-info-box {
    padding:50px
  }
  .qr-frame {
    width: 550px;
    height: 550px;
  }
  
  /* Media Queries for mobile screens */
  @media (max-width: 426px) {
    .qr-reader {
      width: 354px;
      height: 299px;
      padding:10px;
    }
    .qr-frame {
      width: 354px;
      height: 299px;
    }
    .qr-info-box {
      padding:24px
    }
  }